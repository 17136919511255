import Rectangle from "./Geometry/Rectangle";

export default class ImageViewerObject {
  /**
   *
   * @param {number} id
   * @param {string} label
   * @param {string} text
   * @param bbox
   * @param metadata
   * @param canvasObject
   * @param shape
   */
  constructor({id, label, text, bbox, metadata, canvasObject, shape=undefined}) {
    this.id = id;
    this.label = label;
    this.text = text;
    this.bbox = bbox;
    this.metadata = metadata;
    this.canvasObject = canvasObject;
    this.shape = shape;
  }

  getRect() {
    return new Rectangle(this.bbox.x1, this.bbox.y1, this.bbox.x2, this.bbox.y2);
  }

  getDict() {
    const result = {
      id: this.id,
      label: this.label,
      text: this.text,
      bbox: this.bbox,
      metadata: this.metadata,
    };
    if (this.shape) result.shape = this.shape;
    return result;
  }

  // returns null if the attribute does not exist
  getAttributeValue(attributeName) {
    const attr = this.metadata.attributes.find(a => a.key === attributeName);
    if (attr) return attr.value;
    return null;
  }

  getAttributes() {
    const result = [
      {key: 'Class', value: this.label},
      {key: 'Text', value: this.text},
      {key: 'Description', value: this.metadata.description},
      ...this.metadata.attributes
    ]
    return result;
  }

  isGraphObject() {
    return this.shape && this.shape.shape_type === 'graph';
  }

  isValidForHierarchy() {
    return this.label.trim().length > 0 && this.text.trim().length > 0;
  }
}
