import React, {useEffect, useState} from 'react'
import {fabric} from 'fabric'
import {debounceTime} from 'rxjs/operators';
import {API_URL, COLOR_PICKER_PRESET_COLORS, KEYCLOAK_ENABLED, viewerConsts, viewerModes} from "../../constants";
import {
  Button,
  Col,
  Spin,
  Input,
  message,
  Row,
  Select,
  Tree,
  Space,
  Radio,
  Progress,
  Switch, Divider, Menu, Dropdown, Tooltip, Pagination, Table, Card, Typography, Popover, Form, Tag, Tabs
} from "antd";

import {PlusOutlined} from '@ant-design/icons';
import {UpOutlined, AppstoreOutlined} from '@ant-design/icons';
import { EyeOutlined, EyeTwoTone } from '@ant-design/icons';
import {Link, Prompt, withRouter} from 'react-router-dom';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { DownOutlined, ArrowLeftOutlined} from '@ant-design/icons';
import axios from 'axios';

import {ZoomHandler} from "../ImageViewerHandlers/ZoomHandler";
import {authHeader, download, ImageViewerObjectFromCanvas, openInNewTab} from "../../Utilities";
import {HoveringHandler} from "../ImageViewerHandlers/HoveringHandler";
import {CaptionDrawingHandler} from "../ImageViewerHandlers/CaptionDrawingHandler";
import {TagsTableView} from "./TagsTableView";
import {NewObjectHandler} from "../ImageViewerHandlers/NewObjectHandler";
import {MiniMapHandler} from "../ImageViewerHandlers/MiniMapHandler";
import {ObjectData} from "./ObjectData";
import {BehaviorSubject, forkJoin, ReplaySubject, Subject} from "rxjs";
import fileSize from "filesize";
import UserPreferencesService from "../../services/UserPreferencesService";
import {switchMap, take} from "rxjs/operators";
import ImageDownloadService from "../../services/ImageDownloadService";
import {SearchContext} from "../../contexts/SearchContext";
import PanningHandler from "../ImageViewerHandlers/PanningHandler";
import ObjectSelectionHandler from "../ImageViewerHandlers/ObjectHandlers/ObjectSelectionHandler";
import {GraphObjectHandler} from "../ImageViewerHandlers/GraphObject/GraphObjectHandler";
import {WrapperObjectHelper} from "../ImageViewerHandlers/GraphObject/WrapperObjectHelper";
import tinycolor from "tinycolor2";
import ColorPicker from "../Misc/ColorPicker";
import {HierarchiesView} from "./HierarchiesView";
import ImageViewerObject from "../ImageViewerHandlers/ImageViewerObject";
import * as queryString from "query-string";
import {
  DataItemFilter,
  DataSourceFilterApplier,
  DataSourceFiltersEditor, emptyAlias,
  ExactMatchItemFilter, isObjectMatchFilters, PostprocessedSearchItemFilter,
  WildcardsSearchItemFilter
} from "../Misc/DataSourceFilter";
import CacheService from "../../services/CacheService";
import {BeforeUnloadPrompt} from "../Misc/BeforeUnloadPrompt";
import {JSONChangesDetector} from "../../services/ImageViewerChangesDetectService";
import {FieldsEditor} from "./FieldsEditor";
import {
  PageFieldsOperationsHandler, readonlyPageFieldKeys
} from "../ImageViewerHandlers/PageField/PageFieldsOperationsHandler";
import {DrawingsComparison} from "./DrawingComparison/Component/DrawingsComparison";
import {CurrentCnDrawing} from "./DrawingComparison/Model/CurrentCnDrawing";
import {SmartCnResults} from "./DrawingComparison/Model/Smart/SmartCnResults";
import {DrawingsMerging} from "./DrawingMerging/Component/DrawingsMerging";
import {SmartMgConflicts} from "./DrawingMerging/Model/SmartMgConflicts";
import {NewCommentMarkerHandler} from "../ImageViewerHandlers/NewCommentMarkerHandler";
import {BatchedCachedSource, BatchedThumbnails, Thumbnails} from "./Thumbnails/Thumbnails";
import {AutoSizer} from "react-virtualized";
import {MarkerTargetAsAnyCanvasObj, MarkerTargetAsProperObj} from "./Comments/Selection/MarkerBelonging";
import {CommonFilterEditor} from "../Misc/CommonFilterEditor/CommonFilterEditor";
import {SimpleFilterEditor} from "../Misc/CommonFilterEditor/SimpleFilterEditor";
import HierarchiesService from "../../services/HierarchiesService";

const {detect} = require('detect-browser');


function Drag() {
  const [dragging, setDragging] = useState(false);
  const [initialPos, setInitialPos] = useState(null);
  const [initialHeight, setInitialHeight] = useState(null);

  useEffect(() => {
    function onMouseMove(e) {
      if (!dragging) return;
      const tagsListPanel = document.getElementById('tags-list-panel');
      const leftDock = document.getElementById('left-dock');
      const dy = e.clientY - initialPos;
      const newSize = ((initialHeight + dy) * 100) / leftDock.getBoundingClientRect().height;
      tagsListPanel.style.height = `${newSize}%`;
    }

    function onMouseUp(e) {
      setDragging(false);
    }

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    }
  })

  useEffect(() => {
    const tagsListPanel = document.getElementById('tags-list-panel');
    tagsListPanel.style.flex = null;
    return () => {
      tagsListPanel.style.flex = '1 0 0';
    }
  }, [])

  const handleDragStart = e => {
    setDragging(true);
    setInitialPos(e.clientY);
    setInitialHeight(document.getElementById('tags-list-panel').getBoundingClientRect().height);
  };

  return (
      <div
          className={"image-viewer-drag"}
          onMouseDown={handleDragStart}
      />
  );
}


function LabelSelector(props) {
  const [newLabelName, setNewLabelName] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const addLabel = () => {
    props.addLabel(newLabelName);
    setNewLabelName('');
    setDropdownOpen(false);
  }

  return (
      <Select
          size="small"
          style={{ width: 200 }}
          value={props.currentLabel}
          onChange={value => props.setCurrentLabel(value)}
          open={dropdownOpen}
          onDropdownVisibleChange={setDropdownOpen}
          dropdownRender={menu => (
              <div>
                {menu}
                <Divider style={{ margin: '4px 0' }} />
                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                  <Input id="new-object-label-input" style={{ flex: 'auto' }}
                         size="small"
                         value={newLabelName}
                         onPressEnter={(e) => {e.preventDefault(); addLabel()}}
                         onChange={(e) => setNewLabelName(e.target.value)}
                  />
                    <Button id="add-new-label-button" type="link" onClick={addLabel} disabled={newLabelName.trim() === ''}>
                      <PlusOutlined/> Add
                    </Button>
                  {/*</a>*/}
                </div>
              </div>
          )}
      >
        {props.labels.map(label => (
            <Select.Option key={label}>{label}</Select.Option>
        ))}
      </Select>
  )
}

function EditableViewerPrompts({when}) {
  return (
      <>
        <BeforeUnloadPrompt when={when}/>
        <Prompt message={(location, action) => {
          return when() ? "Are you sure to leave the drawing? The changes will be lost." : true;
        }}/>
      </>
  )
}


function Minimap({onMouseDown}) {
  const [collapsed, setCollapsed] = useState(UserPreferencesService.getMiniMapState() === 'hidden');
  const [showCollapseButton, setShowCollapseButton] = useState(false);

  return (
      <>
        <div onMouseOver={e => setShowCollapseButton(true)}  onMouseOut={e => setShowCollapseButton(false)}
             style={{position: 'relative', zIndex: 1000, width: 'fit-content', float: 'right'}}
        >
          <div hidden={collapsed}
               id={"minimap-container"}
               style={{position: 'relative', zIndex: 1000, width: 'fit-content', border: '1px solid black'}}>
            <div>
              <canvas id="minimap" width={200} height={200} style={{position: 'absolute', top: '10px', left: '10px'}} />
              <div style={{width: '200px', height: '15px', background: 'var(--color-gray)'}}>
                <div id="minimap-slider" style={{
                  cursor: 'move', width: '20px', height: '15px', background: 'white', position: 'absolute'}}
                     onMouseDown={onMouseDown}
                />
              </div>
            </div>
          </div>
          <div style={{position: 'relative', zIndex: 1000, float: 'right'}}>
            <Button id="collapse-minimap-button" hidden={!collapsed && !showCollapseButton} size="small"
                    onClick={e => {
                      const newCollapsedValue = !collapsed
                      setCollapsed(newCollapsedValue);
                      UserPreferencesService.setMiniMapState(newCollapsedValue ? 'hidden' : 'shown');
                    }}>
              {collapsed ? <DownOutlined/> : <UpOutlined/>}
            </Button>
          </div>
        </div>
      </>
  );
}

class ImageViewer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hierarchyNodeAttributes: [],
      hierarchySystemAttributes: [],
      labelsList: [],
      currentLabel: 'text',
      selectionParams: null,
      extraVisibleObjectsIds: new Set([]),
      allObjects: null,
      objectById: null,
      tableViewFilters: [],
      searchBoxQuery: "",
      allLabels: null,
      expandedKeys: ['root-node'],
      selectedKeys: [],
      selectedObject: null,
      zoomHandler: null,

      tagsPanelHeight: 0,
      viewMode: 'tree-view',

      downloadProgress: 0,
      downloadProgressText: '',
      downloadProgressActive: true,

      isLoading: true,

      imageQuality: null,

      isEditingPiping: false,
      isCreatingNewPiping: false,

      fileName: null,
      systemAttributes: [],
      pageId: null,
      deleteObjectWithFieldConfirmationModalVisible: false,
      baseAnnotation: null,
      rightDockSpan: UserPreferencesService.getThumbnailsSize()
    };

    this.subscriptions = [];

    this.mouseDown$ = new Subject();
    this.mouseMove$ = new Subject();
    this.mouseUp$ = new Subject();
    this.onMouseWheel$ = new Subject();

    this.escapeKeyPressed$ = new Subject();
    this.deleteKeyPressed$ = new Subject();

    this.newCommentRequested$ = new Subject();

    this.mode$ = new BehaviorSubject(viewerModes.NORMAL);

    this.objectMoving$ = new Subject();


    this.zoomChange$ = new Subject();


    this.imageLoaded$ = new Subject();
    this.imageShown$ = new Subject();
    this.documentLoaded$ = new Subject();
    this.downloadFinished$ = new Subject();

    this.viewHierarchyStateChanged$ = new BehaviorSubject({currentHierarchyId: null});

    this.viewPortChanged$ = new Subject();

    /**
     * emits when an object is selected.
     * target - the selected object. target is null if object was deselected.
     * source - what caused the object selection. can be 'zoom' or 'canvas'.
     * 'zoom' means that the object was selected by zooming to it from treeview, table view, etc.
     * otherwise 'canvas' is used, which corresponds to selecting the object by clicking on it on canvas.
     * @type {BehaviorSubject<{source: 'canvas' | 'zoom' | 'reset' | (string & {}), target?: fabric.Object}>}
     */
    this.objectSelected$ = new BehaviorSubject({target: null, source: 'canvas'});
    /**
     *
     * @type {Subject<{e?: MouseEvent, source: 'canvas', target?: fabric.Object}>
     */
    this.objectMouseClicked$ = new Subject();
    this.commentSourceSelected$ = new BehaviorSubject({target: null});
    this.commentPassedToLink$ = new BehaviorSubject({commentId: null});
    this.anotherDrawingObjectSelected$ = new BehaviorSubject({target: null, source: 'canvas'});

    this.isDragging$ = new BehaviorSubject(false);

    this.visualChanged$ = new Subject();

    // emits when canvas objects are changed
    this.objectsChanged$ = new BehaviorSubject(1);
    // emits after the changes from objectsChanged$ are reflected in this.state.allObjects
    this.allObjectsStateChanged$ = new BehaviorSubject(1);

    this.objectsVisibilityChanged$ = new Subject();
    this.renderAll$ = new Subject();

    // custom isObjectVisible function for certain modes
    this.isObjectVisibleFunc = {}

    this.mode$.next(viewerModes.NORMAL);

    this.extraCanvasObjects = [];

    this.changesDetector = new JSONChangesDetector();

    this.changesDetector.register(
        {
          annotation: {
            workpacks: [],
            objects: []
          }
        }
    );

    this.deb = {}

    this.settings = null;

    this.pageFieldsOperations = new PageFieldsOperationsHandler(this);

    this.subscriptions.push(this.renderAll$.pipe(debounceTime(10)).subscribe(() => {
      this.canvas.requestRenderAll();
    }));

    this.subscriptions.push(this.viewPortChanged$.subscribe(() => {
      const curStrokeWidth = viewerConsts.STROKE_WIDTH / this.zoomHandler.zoomLevel;
      this.canvas.getObjects().forEach((obj) => {
        if (!obj.isProperObject) return;
        if (!obj.visible) return;
        const maxStrokeWidth = Math.min(obj.width * obj.scaleX, obj.height * obj.scaleY) * 0.15;
        obj.set('strokeWidth', Math.min(curStrokeWidth, maxStrokeWidth));
      })
      this.renderAll$.next(1);
    }));

    this.subscriptions.push(this.anotherDrawingObjectSelected$.subscribe(e => this.setState({anotherDrawingObject: e.target})));

    this.subscriptions.push(this.imageLoaded$.pipe(switchMap(e =>
      forkJoin({
          imageShown: this.imageShown$.pipe(take(1)),
          documentLoaded: this.documentLoaded$.pipe(take(1)),
      })
    )).subscribe(res => {
      this.downloadFinished$.next(res.documentLoaded)
    }));
    // this.lastPoint = {x: 0, y: 0};
  }

  handlePageChange = (newPage) => {
    const isFinalResult = this.props.location.pathname.indexOf('/final_results/view_result/') !== -1;
    const resultId = isFinalResult ? this.props.parentProps.match.params.finalResultId : this.props.parentProps.match.params.documentId;
    const matchParams = this.props.parentProps.match.params;
    // debugger;
    axios.get(API_URL + `/projects/${matchParams.projectId}/get_other_page_info`, {
      params: {
        page_number: newPage - 1,
        result_id: resultId,
        is_final: isFinalResult,
      },
      headers: authHeader()
    }).then(result => {
      this.changePage(
          result.data.id,
          result.data.is_final,
          matchParams.projectId,
          result.data.run_id
      );
      // window.location.reload();
    }).catch(() => {
      message.error('Failed to change the page');
    })
  }

  changePage(resultId, isFinal, projectId, runId) {
    if (isFinal)
      this.props.history.push(`/project/${projectId}/final_results/view_result/${resultId}`);
    else
      this.props.history.push(`/project/${projectId}/explore_results/${runId}/view_document/${resultId}`);
  }

  handleBack = () => {
    this.props.history.goBack();
  }

  isTableViewFiltersPresent = () => {
    return this.state.tableViewFilters.length > 0;
  }

  _isObjectVisible = (obj) => {
    if (!obj.objectMetadata) return true;
    const labels = this.state.selectionParams;
    let isVisible = labels.includes(obj.objectMetadata.label) || obj === this.canvas.getActiveObject()
        || this.state.extraVisibleObjectsIds.has(obj.objectMetadata.id);

    if (this.isTableViewFiltersPresent()) {
      isVisible = isVisible || isObjectMatchFilters(obj.objectMetadata, this.state.tableViewFilters);
    }

    return isVisible;
  }

  isObjectVisible = (obj) => {
    let func = this.isObjectVisibleFunc[this.mode$.value];
    if (!func) func = this._isObjectVisible;
    return func(obj);
  }

  updateObjectVisibility = (obj) => {
    if (this.state.viewMode === "merge-view") {
        const mgConflicts = new SmartMgConflicts(
            this.state.mgConflicts
        );

        mgConflicts.updateVisibility(this, obj);
    } else if (this.state.viewMode === "comparison-view") {
      const cnResults = new SmartCnResults(
          this.state.cnResults
      );

      cnResults.updateVisibility(this, obj);
    } else {
      const isVisible = this.isObjectVisible(obj);

      if (obj.objectMetadata?.shape?.shape_type === 'graph') {
        obj.wrappingData.helper.setVisibility(isVisible);
      } else {
        obj.visible = isVisible;
        obj.selectable = isVisible;

        if (this.isTableViewFiltersPresent() && isObjectMatchFilters(obj.objectMetadata, this.state.tableViewFilters)) {
          obj.baseFillColor = 'rgba(0, 255, 0, 0.6)';
        } else {
          obj.baseFillColor = 'rgba(0, 0, 0, 0)';
        }

        obj.fill = obj.baseFillColor;
      }
    }

    this.renderAll$.next(1);
  }

  updateObjectsVisibility = () => {
    this.canvas.getObjects().forEach((obj) => {
      if (obj.isProperObject || obj.fromAnotherDrawing) {
        this.updateObjectVisibility(obj);
      }
    })
    this.renderAll$.next(1);
    this.visualChanged$.next(1);
    this.objectsVisibilityChanged$.next(1);
  }

  setTableViewFilters = (filters) => {
    this.setState({tableViewFilters: filters}, this.updateObjectsVisibility);
  }

  handleCheck = (checkedKeys) => {
    this.setState({
      selectionParams: checkedKeys.filter((key) => key !== 'root-node'),
      extraVisibleObjectsIds: new Set(),
    }, () => {
      this.updateObjectsVisibility();
    })
  };

  handleExpand = (expandedKeys) => {
    this.setState({expandedKeys: expandedKeys});
  }

  getCanvasObjects = () => {
    const result = this.canvas.getObjects().filter((obj) => obj.isProperObject).map((obj) => {
      return new ImageViewerObjectFromCanvas(obj);
    })

    return result
  }

  getCanvasObjectsDicts = () => {
    return this.getCanvasObjects().map(obj => obj.getDict());
  }

  handleSave = () => {
    const result = this.getCanvasObjects().map(obj => obj.getDict());

    const annotationToSave = {
      ...this.state.baseAnnotation,
      workpacks: [], // erasing existing workpacks to avoid issues with inconsistent IDs
      objects: result,
    }

    this.props.saveChanges(annotationToSave).then(response => {
      this.changesDetector.register(
          {
            annotation: annotationToSave
          }
      );

      message.success('Changes saved')
    }).catch(() => message.error('Saving changes failed'))
  }

  onNextFrame = (callback) => {
    this.onNextFrameTimeout = setTimeout(function () {
      requestAnimationFrame(callback)
    })
  }

  getColor(labelName) {
    return this.settings.labels.find(label => label.name === labelName)?.color || '#0000FF99';
  }

  /**
   *
   * @returns {ImageViewerObject | undefined}
   */
  getCurrentObject = () => {
    if (!this.state.selectedObject) return null;
    return this.canvasObjectToObject(this.state.selectedObject);
  }

  /**
   *
   * @param canvasObject
   * @returns {ImageViewerObject | undefined}
   */
  canvasObjectToObject = (canvasObject) => {
    return this.state.objectById[canvasObject?.objectMetadata?.id];
  }

  /**
   *
   * @param {ImageViewerObject} obj
   * @returns {fabric.Object | undefined}
   */
  objectToCanvasObject = (obj) => {
    return (this.canvas?.getObjects() ?? [])
        .filter((x) => x.objectMetadata)
        .find((x) => x.objectMetadata.id === obj.id);
  }

  initCanvas = () => {
    console.log('!!!! Start loading')
    this.deb.loadingStart = performance.now();

    const strokeColor = viewerConsts.COLOR_BLUE;

    // TODO: rewrite this
    const c = document.getElementById('c');
    const cont = document.getElementById('cont');
    this.canvasContainer = cont;

    // panel with tags
    const tagsPanel = document.getElementById("tags-list-panel");
    this.setState({
      tagsPanelHeight: tagsPanel.offsetHeight
    });

    c.width = cont.offsetWidth;
    c.height = cont.offsetHeight;

    // default textureSize is smaller than 5000 (image size) and
    // causes breaking of canvas when image resizing filter e.g. lanczos is used.
    // fabric.textureSize = 8192;
    fabric.imageSmoothingEnabled = true;
    // fabric.enableGLFiltering = false;
    fabric.textureSize = 6192;

    let canvas = new fabric.Canvas("c");//, {renderOnAddRemove: false});

    const ctx = canvas.getContext();
    ctx.imageSmoothingQuality = 'high';

    fabric.util.setImageSmoothing(canvas, true);

    canvas.uniformScaling = false;

    this.canvas = canvas

    this.subscriptions.push(this.mode$.subscribe(this.updateObjectsVisibility));

    canvas.on('object:scaling', e => {
      e.target.isResizing = true;
    })

    canvas.on('object:scaled', e => {
      e.target.isResizing = false;
      this.objectsChanged$.next(1);
    })

    canvas.on('mouse:down', opt => this.mouseDown$.next(opt));
    canvas.on('mouse:move', opt => this.mouseMove$.next(opt));
    canvas.on('mouse:up', opt => this.mouseUp$.next(opt));

    canvas.on('object:moving', opt => this.objectMoving$.next(opt));

    canvas.on('mouse:down:before', opt => {
      document.activeElement.blur();
    });


    canvas.on('object:moved', () => {
      this.objectsChanged$.next(1);
    })

    canvas.defaultCursor = 'default';

    console.log('!!!! Before loading annotation')
    this.deb.beforeLoadingAnnotation = performance.now();

    if (this.props.loadHierarchyNodeAttributes) {
      this.subscriptions.push(this.viewHierarchyStateChanged$.subscribe(e => {
        if (e.currentHierarchyId) {
          this.setState({hierarchyNodeAttributesLoading: true});
          this.props.loadHierarchyNodeAttributes(
              e.currentHierarchyId
          ).then(
            nodeAttrResponse => {
              return HierarchiesService.fetchSystemAttributes(e.currentHierarchyId).then(systemAttrResponse => {
                if (this.viewHierarchyStateChanged$.value.currentHierarchyId === e.currentHierarchyId) {
                  this.setState({
                    hierarchyNodeAttributes: nodeAttrResponse.data,
                    hierarchySystemAttributes: systemAttrResponse,
                    hierarchyNodeAttributesLoading: false
                  });
                }
              })
            }
          ).catch(
            _ => {
              message.error('Failed to load Hierarchy Table View filters');
              this.setState({hierarchyNodeAttributesLoading: false});
            }
          );
        } else {
          this.setState({
            hierarchyNodeAttributes: [],
            hierarchySystemAttributes: [],
            hierarchyNodeAttributesLoading: false
          });
        }
      }));
    }

    this.subscriptions.push(this.imageLoaded$.subscribe(e => {
      const browser = detect();

      // console.log('!!!! Image received')
      // this.deb.imageReceived = performance.now();

      fabric.Image.fromURL(e.imgUrlBinary, img => {
        if (e.imageQuality === 'high' && browser && browser.name === 'firefox') {
          img.resizeFilter = new fabric.Image.filters.Resize({
            resizeType: 'lanczos',
            lanczosLobes: 1,
          });
          img.applyResizeFilters();
        }

        if (!this.zoomHandler) {
          const minZoomLevel = Math.min(canvas.width / img.width, canvas.height / img.height);
          const maxZoomLevel = 5;
          this.zoomHandler = new ZoomHandler(this, minZoomLevel, maxZoomLevel);
          this.zoomHandler.registerEvents();

          this.imageWidth = img.width;
          this.imageHeight = img.height;
        }

        // reset zoom only if tagHash is absent
        let tagHash;
        if (KEYCLOAK_ENABLED) {
          tagHash = (this.props.location?.hash || '').split("state=")[0].substring(1);
        } else {
          tagHash = this.props.location?.hash.substring(1) || '';
        }
        if (tagHash.length === 0) {
          if (!e.keepZoom) {
            this.zoomHandler.resetZoom();
          }
        }

        console.log('!!!! Before render canvas')
        this.deb.beforeRenderCanvas = performance.now();

        this.img = img;
        console.log('min Zoom:', this.minZoomLevel);
        canvas.setBackgroundImage(img, () => {
          this.renderAll$.next(1);
          this.setState({
            isLoading: false,
          });
          this.imageShown$.next('loaded')
          console.log('!!!! After render canvas')
          this.deb.afterRenderCanvas = performance.now();
          console.log(`!!!! Total load time: ${this.deb.afterRenderCanvas - this.deb.mountTime}`)
        });

        window.testsHelping = {
          canvas: canvas
        }

      });

      this.subscriptions.push(this.downloadFinished$.subscribe(e => {
        const currentAnnotation = e.annotation;
        this.baseAnnotation = currentAnnotation;

        this.setState({baseAnnotation: currentAnnotation});

        let all_labels = [];
        // const allTags = currentAnnotation.objects.map((tag, ind) => ({ ...tag, id: ind }));
        let allTags = [];
        let visibleLabels = []

        currentAnnotation.objects.forEach((obj) => {
          // TODO: refactor
          const isGraph = obj.shape?.shape_type === 'graph';
          let rect;
          if (isGraph) {
            rect = WrapperObjectHelper.buildWrapperObject(obj, this);
          } else {
            rect = new fabric.Rect({
              ...viewerConsts.DEFAULT_OBJECT_ARGS,
              width: Math.round(obj.bbox.x2 - obj.bbox.x1),
              height: Math.round(obj.bbox.y2 - obj.bbox.y1),
              // stroke: strokeColor,
              stroke: this.getColor(obj.label),
              top: Math.round(obj.bbox.y1),
              left: Math.round(obj.bbox.x1),
              strokeWidth: viewerConsts.STROKE_WIDTH,

              rx: 0.5,
              ry: 0.5,
            });

            if (this.props.editable) {
              rect.setControlsVisibility({
                mt: false,
                mb: false,
                ml: false,
                mr: false,
                mtr: false,
              });
            } else {
              rect.hasControls = false;
              rect.lockMovementX = true;
              rect.lockMovementY = true;
            }

            let metadata = obj.metadata ?? {};
            metadata.description = metadata.description ?? '';
            metadata.attributes = metadata.attributes ?? [];

            rect.objectMetadata = {
              id: obj.id,
              label: obj.label,
              text: obj.text,
              metadata: metadata,
            }
            rect.isProperObject = true;
          }
          all_labels.push(obj.label);
          visibleLabels.push(obj.label);

          if (isGraph) {
            rect.wrappingData.helper.addToCanvas();
          } else {
            this.canvas.add(rect);
          }
          allTags.push({...obj, canvasObject: rect});
        })

        this.context.setAutoCompleteOptions([...new Map(allTags.map(item => [item["text"], item.text])).values()]);

        this.lastObjectIndex = allTags.map(obj => obj.id).reduce((a, e) => Math.max(a, e), -1);

        all_labels.push('text');
        all_labels.push('zone');
        const uniqueLabels = [...new Set(all_labels)].sort();

        visibleLabels = [...new Set(visibleLabels)].filter(label => label !== 'text');
        this.setState({selectionParams: visibleLabels}, this.updateObjectsVisibility);
        this.setState({labelsList: uniqueLabels, currentLabel: uniqueLabels[0]})
        this.setState({allTags: allTags});

        this.handlers = {
          hoveringHandler: new HoveringHandler(this),
          captionDrawingHandler: new CaptionDrawingHandler(this),
          newObjectHandler: new NewObjectHandler(this),
          miniMapHandler: new MiniMapHandler(this),
          panningHandler: new PanningHandler(this),
          objectSelectionHandler: new ObjectSelectionHandler(this),
          graphObjectHandler: new GraphObjectHandler(this),
          newCommentMarkerHandler: new NewCommentMarkerHandler(this)
        };
        Object.values(this.handlers).forEach(handler => handler.registerEvents());

        this.setState({zoomHandler: this.zoomHandler});

        this.handlers.miniMapHandler.initMinimap();
        this.handlers.miniMapHandler.updateMiniMapVP()
        this.handlers.miniMapHandler.minimap.renderAll();

        this.subscriptions.push(this.objectsChanged$.subscribe(() => {
          const oldLabels = new Set((this.state.allObjects || []).map((obj) => obj.label));
          const newObjects = this.getCanvasObjects();
          const newLabels = [...new Set(newObjects.map((obj) => obj.label))];
          let labelsToAdd = newLabels.filter(label => (!oldLabels.has(label) && !this.state.selectionParams.includes(label)));
          if (!this.state.allObjects) labelsToAdd = [];
          const objectById = Object.assign({}, ...newObjects.map((obj) => ({[obj.id]: obj})));
          this.setState({
                allObjects: newObjects,
                objectById: objectById,
                selectionParams: [...this.state.selectionParams, ...labelsToAdd],
                allLabels: this.getAllLabels(newObjects)
              },
              () => {
                this.viewPortChanged$.next(1);
                this.updateObjectsVisibility();
                this.allObjectsStateChanged$.next(1);
              }
          );
        }));

        // zoom to tag from the link anchor if present
        if (this.props.location) {
          let tagHash;
          if (KEYCLOAK_ENABLED) {
            tagHash = (this.props.location?.hash || '').split("state=")[0].substring(1);
          } else {
            tagHash = this.props.location?.hash.substring(1) || '';
          }
          if (tagHash.length > 0) {
            try {
              const tagHashParts = tagHash.split(",")

              const tagNumber = new Buffer(tagHashParts[0], 'base64').toString('utf-8')

              const tagId = tagHashParts.length > 1 ?
                  new Buffer(tagHashParts[1], 'base64').toString('utf-8')
                  : null;

              // in case of multiple matches prefer non-text objects
              const comparer = (o1, o2) => {
                return (o1.objectMetadata?.label === 'text') - (o2.objectMetadata?.label === 'text');
              }

              const zoomCandidates = [...this.canvas.getObjects()].sort(comparer)
                  .filter((obj) => {
                    return obj.isProperObject
                        && obj.objectMetadata.text === tagNumber
                  });

              let tagObj = null;

              if (tagId != null) {
                tagObj = zoomCandidates.find((obj) => obj.objectMetadata.id.toString() === tagId);
              }
              // found nothing by id or id was not provided
              if (tagObj == null && zoomCandidates.length > 0) {
                tagObj = zoomCandidates[0];
              }

              setTimeout(() => {
                if (tagObj) {
                  this.zoomHandler.zoomToObject(tagObj);
                }
              });
            } catch (e) {
              console.log(`Error. Tag not found. Hash: ${tagHash}`)
            }
          }
        }

        this.changesDetector.register(
            {
              annotation: {
                ...this.state.baseAnnotation,
                workpacks: [],
                objects: this.getCanvasObjectsDicts(),
              }
            }
        );

        // switch to hierarchy view if hierarchy_id is present
        const urlParams = queryString.parse(this.props.location.search)
        if (this.props.hierarchiesAllowed && urlParams.hierarchy_id) {
          this.setState({
            viewMode: 'hierarchies-view',
          }, () => {
            this.hierarchiesViewRef.current.setState({
              mode: 'view',
              currentHierarchyId: urlParams.hierarchy_id,
            });
          })
        }

        if (urlParams.comment_id) {
          this.commentPassedToLink$.next({commentId: urlParams.comment_id});
        }
      }));
    }));
  }

  onDownloadProgress = (progressEvent) => {
    const total = progressEvent.total;
    const current = progressEvent.loaded;

    const percentCompleted = Math.floor(current / total * 100);
    const curProgressText = `${fileSize(current)} / ${fileSize(total)}`;
    console.log('!!! %', percentCompleted);

    this.setState({downloadProgress: percentCompleted, downloadProgressText: curProgressText});
    console.log('completed: ', percentCompleted)
  }

  handleSearchWithinDrawing = (searchQuery, searchSettings) => {
    if (this.state.isWorkpackOpened || ["comparison-view", "merge-view"].includes(this.state.viewMode)) {
      return;
    }

    let searchFilter;

    if (searchSettings.useWildcards) {
      searchFilter = new WildcardsSearchItemFilter(searchQuery.trim(), (el) => el.text, "Search")
    } else {
      searchFilter = new PostprocessedSearchItemFilter(searchQuery.trim(), (el) => el.text, "Search")
    }

    this.setTableViewFilters(searchFilter.addTo(this.state.tableViewFilters));

    this.setState({viewMode: 'table-view', searchBoxQuery: searchQuery});
  }

  zoomToCommentMarker = marker => {
    const relatedObj = this.getCanvasObjects()
        .find(obj => new MarkerTargetAsProperObj(obj).containsMarker(marker));

    if (relatedObj?.canvasObject) {
      this.zoomHandler.zoomToObject(relatedObj.canvasObject, true, false, 'commentMarker');
    } else {
      this.resetSelectedObject();
      const relatedCanvasMarker = this.canvas.getObjects()
          .filter(obj => obj.isCommentMarker)
          .find(obj => new MarkerTargetAsAnyCanvasObj(obj).containsMarker(marker));
      if (relatedCanvasMarker) {
        this.zoomHandler.zoomToObject(relatedCanvasMarker, false, false, 'commentMarker');
      }
    }
  }

  resetSelectedObject = () => {
    this.objectSelected$.next({target: null, source: "reset"});
  }

  componentDidMount() {
    this.context.setSearchHandler(this.handleSearchWithinDrawing);
    const searchHandlerSchema = {
      owner: this,
      searchType: 'search_in_current_drawing',
      handler: this.handleSearchWithinDrawing
    }

    this.context.addSearchHandlerSchema(searchHandlerSchema);

    console.log('!!! mount')
    this.deb.mountTime = performance.now();

    const currentImageQuality = UserPreferencesService.getDrawingImageQuality();

    let currentAnnotation = null;

    this.setState({
      downloadProgress: 0,
      downloadProgressText: '',
      downloadProgressActive: true,
    });
    const pr1 = axios.get(API_URL + `/projects/${this.props.projectId}/settings`, {headers: authHeader()}
    ).then(res => {
          // this.setState({settings: res.data.settings}, callback)
          this.settings = res.data.settings;
          console.log('!!!2 settings loaded');
        }
    ).catch(err => {
      if (err?.response?.status !== 401) {
        message.error('failed to load labels');
      }
    });
    const pr2 = this.props.loadAnnotation().then(result => {
      currentAnnotation = result.data.annotation;

      this.setState({
        fileName: currentAnnotation.fields.filter(f => f.key === 'File name')[0]?.value,
        pageInfo: {number: result.data.page_number, total: result.data.pages_count},
        pageId: result.data.page_id,
      })

      const imageDownloadService = new ImageDownloadService(result.data.annotation.image_id);

      return imageDownloadService.downloadImage(
        currentImageQuality === 'medium',
        this.onDownloadProgress,
      )
    }).then(imgUrlBinary => {
      this.imageLoaded$.next({
        imgUrlBinary: imgUrlBinary, imageQuality: currentImageQuality
      })
    });

    axios.all([pr1, pr2]).then((res) => {
      console.log('!!!2x Image received:ev')
      // this.dispatch(documentLoadedEvent);
      this.documentLoaded$.next({annotation: currentAnnotation})
      console.log('!!!2 fin');
      console.log(`!!!2 Total load time: ${performance.now() - this.deb.mountTime}`)
    })

    this.setState({imageQuality: currentImageQuality});

    CacheService.getProjectData(this.props.projectId).then(projectData => {
      axios
          .get(
              API_URL + `/workspaces/${projectData.workspace_id}/system_attributes`,
              {headers: authHeader()}
          )
          .then(response => {
            this.setState({systemAttributes: response.data})
      })
    });

    // onNextFrame is used to correctly calculate canvas size after the page is fully rendered
    this.onNextFrame(() => {
      this.initCanvas()
    })

    this.treeRef = React.createRef();
    this.tableRef = React.createRef();
    this.hierarchiesViewRef = React.createRef();
  }

  componentWillUnmount() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    if (this.onNextFrameTimeout) clearTimeout(this.onNextFrameTimeout);
    this.context.setAutoCompleteOptions([]);
    this.context.removeSearchHandlerSchemasByOwner(this);
  }

  onViewModeChange = (e) => {
    const newMode = e.target.value;
    if (newMode === 'hierarchies-view') {
      // Clear filters upon switching to the hierarchy tab.
      this.setTableViewFilters([]);
    }
    this.setState({
      viewMode: newMode,
    })
  }

  getTableViewFilterEditor = () => {
    const allObjects = this.state.allObjects ?? [];
    const systemAttributes = this.state.systemAttributes;
    const hierarchySystemAttributes = this.state.hierarchySystemAttributes;
    const hierarchyNodeAttributes = this.state.hierarchyNodeAttributes;

    const filterSchemas = [
      {
        attribute: "Class",
        type: 'obj_attr',
        allowedValues: allObjects.map((obj) => obj.label),
        constructor(value) {
          return new ExactMatchItemFilter(value, (e) => e.label, "Class");
        }
      },
      {
        attribute: "Description",
        type: 'obj_attr',
        allowedValues: allObjects.map((obj) => obj.metadata.description),
        constructor(value) {
          return new ExactMatchItemFilter(value, (e) => e.metadata.description, "Description");
        }
      },
      {
        attribute: "Text",
        type: 'obj_attr',
        allowedValues: allObjects.map((obj) => obj.text),
        constructor(value) {
          return new ExactMatchItemFilter(value, (e) => e.text, "Text");
        }
      },
    ];

    const allAttributes = allObjects.flatMap(obj => {
      const objAttributes = obj.metadata.attributes;
      const allowedSystemAttributesByType = this.state.systemAttributes.filter(systemAttr => systemAttr.object_label === obj.label);

      let localSystemAttributes = [];
      allowedSystemAttributesByType.forEach(systemAttr => {
          const isFilledSystemAttributePresent = objAttributes.find(attr => systemAttr.attribute_name === attr.key);

          if (!isFilledSystemAttributePresent) {
            localSystemAttributes.push({
              key: systemAttr.attribute_name,
              value: ""
            })
          }
      });

      return objAttributes.concat(localSystemAttributes);
    });

    allAttributes.forEach((element) => {
      let filterSchema = filterSchemas.find((schema) => schema.attribute === element.key);

      if (!filterSchema) {
        filterSchema = {
          attribute: element.key,
          type: 'obj_attr',
          allowedValues: [],
          constructor(value) {
            const dataExtractor = (e) => {
              const result = e.metadata.attributes.find((attr) => attr.key === element.key);

              if (result) {
                return result.value;
              }

              const possibleSystemAttribute = systemAttributes
                  .filter(systemAttr => systemAttr.object_label === e.label)
                  .find((systemAttr => systemAttr.attribute_name === element.key));

              if (possibleSystemAttribute) {
                return "";
              }

              return null;
            }

            return new ExactMatchItemFilter(value, dataExtractor, element.key);
          }
        }

        filterSchemas.push(filterSchema);
      }

      filterSchema.allowedValues.push(element.value);
    })

    hierarchySystemAttributes.forEach(attr => {
      const attrName = attr.name;
      filterSchemas.push({
        attribute: attrName,
        type: 'node_attr',
        allowedValues: hierarchyNodeAttributes.filter(obj => obj.label === attr.label).map((obj) => {
          const systemAttr = obj.attributes.find(x => x.key === attrName);
          if (systemAttr) {
            return systemAttr.value;
          } else {
            return '';
          }
        }),
        constructor(value) {
          const extractValue = obj => {
            const nodeAttr = hierarchyNodeAttributes
                .filter(x => x.object_id === obj.id)
                .flatMap(x => x.attributes)
                .find(x => x.key === attrName)
            ;
            if (nodeAttr?.value != null) {
              return nodeAttr?.value;
            } else if (obj.label === attr.label && hierarchyNodeAttributes.find(x => x.object_id === obj.id)) {
              // obj has shadow system attribute
              return '';
            } else {
              return null;
            }
          }
          return new ExactMatchItemFilter(value, extractValue, `(Node) ${attrName}`);
        }
      })
    })

    const filterEditors = [
      {
        type: 'obj_attr',
        tabName: 'Object Attribute',
        render: (schemasByType, onEditedFilterChanges) => (
            <SimpleFilterEditor
                filterSchemas={schemasByType}
                onEditedFilterChanged={onEditedFilterChanges}
            />
        )
      }
    ];
    if (filterSchemas.filter(x => x.type === 'node_attr').length > 0) {
      filterEditors.push({
          type: 'node_attr',
          tabName: 'Node Attribute',
          render: (schemasByType, onEditedFilterChanges) => (
              <SimpleFilterEditor
                  filterSchemas={schemasByType}
                  onEditedFilterChanged={onEditedFilterChanges}
              />
          )
        }
      )
    }

    return (
        <DataSourceFiltersEditor
            itemFilters={this.state.tableViewFilters}
            onItemFiltersChanged={this.setTableViewFilters}
            filterSchemas={filterSchemas}
            fieldEditorRenderer={(filterSchemas, addNewFilter) => (
                <CommonFilterEditor
                    title={(
                        <>
                          <div>Add filter</div>
                          {this.state.hierarchyNodeAttributesLoading && (
                              <span>Loading node filters... <Spin spinning={true}/></span>
                          )}
                        </>
                    )}
                    filterSchemas={filterSchemas}
                    onNewFilter={addNewFilter}
                    editors={filterEditors}
                />
            )}
            contentRenderer={(tagsList, editor) => (
                <Row justify={"space-between"}>
                  <Col span={15}>
                    {tagsList}
                  </Col>
                  <Col>
                    <span style={{marginLeft: 'auto', marginRight: '16px'}}>
                        {editor}
                    </span>
                  </Col>
                </Row>
            )}
        />
    )
  }

  getTableView = () => {
    const getSearchQuery = () => {
      const searchFilter = this.state.tableViewFilters.find((filter) => filter.filterAlias === "Search");
      if (!searchFilter) {
        return "";
      }

      return searchFilter.value;
    }

    return (
        <>
          <DataSourceFilterApplier
            itemFilters={this.state.tableViewFilters}
            dataSource={this.state.allObjects ?? []}
            dataSourceRenderer={
              (filteredDataSource) => <TagsTableView
                  {...this.props}
                  highlightAsWildcards={this.state.tableViewFilters.find(el => el instanceof WildcardsSearchItemFilter) != null}
                  searchQuery={getSearchQuery()}
                  tags={filteredDataSource}
                  clickOnTag={(tag) => this.zoomHandler.zoomToObject(tag.canvasObject)}
                  ref={this.tableRef}
              />
            }
          />
        </>
    )
  }

  getHierarchiesView = () => {
    return (
        <HierarchiesView imageViewer={this} ref={this.hierarchiesViewRef}/>
    )
  }

  handleQualityChange = checked => {
    const newQuality = checked ? 'high' : 'medium';
    UserPreferencesService.setDrawingImageQuality(newQuality);

    const imageDownloadService = new ImageDownloadService(this.baseAnnotation.image_id);
    this.setState({
      downloadProgress: 0,
      downloadProgressText: '',
      downloadProgressActive: true,
      isLoading: true,
    });
    imageDownloadService.downloadImage(
        newQuality === 'medium',
        this.onDownloadProgress,
    ).then(imgUrlBinary => {
      this.imageLoaded$.next({
        imgUrlBinary: imgUrlBinary, imageQuality: newQuality, keepZoom: true
      })
    })

    this.setState({imageQuality: newQuality});
  }

  getTagLinks = (tagObject) => {
    const objectLinks = tagObject.metadata.links;
    if (objectLinks && objectLinks.length > 0)
      return objectLinks
    return null;
  }

  generateTagExternalLink = (tagObject) => {
    const tagLinks = this.getTagLinks(tagObject);
    if (tagLinks.length === 1) {
      return (<img
          onClick={() => openInNewTab(tagLinks[0].target)}
          src={process.env.PUBLIC_URL + '/images/external-link-icon.svg'}
          width={16} height={16} style={{marginLeft: '8px'}}
      />)
    } else {
      const menu = (<Menu>
        {
          tagLinks.map(link => (
              <Menu.Item>
                <a onClick={() => openInNewTab(link.target)}>{link.name}</a>
              </Menu.Item>
          ))
        }
      </Menu>);
      return (
          <Dropdown overlay={menu} trigger="click">
            <img src={process.env.PUBLIC_URL + '/images/external-link-icon.svg'} width={16} height={16} style={{marginLeft: '8px'}}/>
          </Dropdown>
      )
    }

  }

  getAllLabels = (objects) => {
    const allLabels = [...new Set(objects.map((obj) => obj.label))];
    return allLabels;
  }

  isShowAllDisplayed = () => {
    const selectedLabelsFiltered = this.state.selectionParams.filter(label => label !== 'text')
    const allLabelsFiltered = this.state.allLabels.filter(label => label !== 'text');
    return selectedLabelsFiltered.length !== allLabelsFiltered.length;
  }

  handleShowAll = () => {
    const labelsFiltered = this.state.allLabels.filter(label => label !== 'text');
    this.setState(
        {selectionParams: labelsFiltered, extraVisibleObjectsIds: new Set()},
        this.updateObjectsVisibility
    );
  }

  handleHideAll = () => {
    this.setState(
        {selectionParams: [], extraVisibleObjectsIds: new Set()},
        this.updateObjectsVisibility
    );
  }

  handleExportPDF = () => {
    this.props.loadAnnotation().then(annotation_response=>{
      const selectedLabels = this.state.selectionParams;

      axios.post(
          API_URL + `/all_results/export_annotation_to_pdf`,
          {
            "selected_labels": selectedLabels,
            "annotation": annotation_response.data.annotation
          },
          {headers: authHeader(), responseType: 'blob'}
      ).then(response => {
        const blob = new window.Blob([response.data], { type: 'application/pdf' });

        const resultFileName = this.state.fileName.split("/").slice(-1)[0]

        download(blob, `${resultFileName}.page${this.state.pageInfo.number + 1}.pdf`);
      }).catch(err => {
        message.error('Failed to export');
      });

      message.info("Export started");
    })
  }

  setMgConflicts = (conflicts) => {
    new SmartMgConflicts(this.state.mgConflicts).removeFromCanvas();

    // if (this.canvas && this.canvas.getActiveObject()) {
    //   const activeObjectAmongCanvasObjects = this.canvas.getObjects().find(obj => obj === this.canvas.getActiveObject());
    //
    //   if (!activeObjectAmongCanvasObjects) {
    //     this.canvas.discardActiveObject();
    //
    //     this.anotherDrawingObjectSelected$.next({target: null, source: "canvas"});
    //     this.objectSelected$.next({target: null, source: "canvas"});
    //   }
    // }

    this.setState({mgConflicts: conflicts}, () => {
      new SmartMgConflicts(conflicts).addToCanvas();

      this.objectsChanged$.next(1);
    });
  }

  setMgFilters = (filters) => {
    this.setState({mgFilters: filters, selectionParams: [], extraVisibleObjectsIds: new Set()},
        this.updateObjectsVisibility);
  }

  createNewMgObject = (obj) => {
    const newId = this.lastObjectIndex += 1;

    const isGraph = obj.shape?.shape_type === 'graph';
    let rect;
    if (isGraph) {
      rect = WrapperObjectHelper.buildWrapperObject({...obj, id: newId}, this);
    } else {
      rect = new fabric.Rect({
        ...viewerConsts.DEFAULT_OBJECT_ARGS,
        width: Math.round(obj.bbox.x2 - obj.bbox.x1),
        height: Math.round(obj.bbox.y2 - obj.bbox.y1),
        // stroke: strokeColor,
        stroke: this.getColor(obj.label),
        top: Math.round(obj.bbox.y1),
        left: Math.round(obj.bbox.x1),
        strokeWidth: viewerConsts.STROKE_WIDTH,

        rx: 0.5,
        ry: 0.5,
      });

      if (this.props.editable) {
        rect.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          mtr: false,
        });
      } else {
        rect.hasControls = false;
        rect.lockMovementX = true;
        rect.lockMovementY = true;
      }

      let metadata = obj.metadata ?? {};
      metadata.description = metadata.description ?? '';
      metadata.attributes = metadata.attributes ?? [];

      rect.objectMetadata = {
        id: newId,
        label: obj.label,
        text: obj.text,
        metadata: metadata,
      }
      rect.isProperObject = true;
    }

    const all_labels = this.state.labelsList;

    all_labels.push(obj.label);

    const uniqueLabels = [...new Set(all_labels)].sort();

    if (isGraph) {
      rect.wrappingData.helper.addToCanvas();
    } else {
      this.canvas.add(rect);
    }

    if (this.state.anotherDrawingObject?.objectMetadata?.id === obj.id) {
      this.anotherDrawingObjectSelected$.next({target: null, source: "canvas"});
      this.objectSelected$.next({target: null, source: "canvas"});
    }

    this.setState({labelsList: uniqueLabels});
    this.setState({allTags: [...this.state.allTags, {...obj, canvasObject: rect}]});

    return rect.objectMetadata;
  }

  deleteMgObject = (id) => {
    const obj = this.canvas.getObjects()
        .filter(obj => obj.isProperObject)
        .filter(obj => obj.objectMetadata != null)
        .find(obj => obj.objectMetadata.id === id);

    if (obj === this.canvas.getActiveObject()) {
      this.setState({selectedObject: null, selectedKeys: []}, () => {
        this.objectSelected$.next({target: null, source: 'canvas'});
        this.anotherDrawingObjectSelected$.next({target: null, source: 'canvas'});
      });
    }

    if (obj.objectMetadata?.shape?.shape_type === 'graph') {
      obj.wrappingData.helper.removeItself();
    } else {
      this.canvas.remove(obj);
    }

    const assignedField = this.pageFieldsOperations.findAssignedFieldForObjectById(obj.objectMetadata?.id);
    const fields = this.pageFieldsOperations.getPageFields();

    const updatedFields = fields.map(field => {
      if (field !== assignedField) return field;

      delete field['drawing_object_id'];

      return field;
    });

    this.setState({baseAnnotation: {...this.state.baseAnnotation, fields: updatedFields}});
  }

  replaceMgObject = (oldObj, newObj) => {
    this.deleteMgObject(oldObj.id);
    const created = this.createNewMgObject(newObj);

    created.id = oldObj.id;

    return created;
  }

  setCnResults = (results) => {
    this.setState({cnResults: results}, this.updateObjectsVisibility);
  }

  setCnFilters = (f) => {
    this.setState({cnFilters: f, selectionParams: [], extraVisibleObjectsIds: new Set()},
        this.updateObjectsVisibility);
  }

  setRightDockSpan = (size) => {
    this.setState({rightDockSpan: size});
    UserPreferencesService.setThumbnailsSize(size);
  }

  renderObjectData = () => {
    return (
        <ObjectData
            imageViewer={this}
            commentsAllowed={this.props.commentsAllowed}
            selectedObject={this.state.selectedObject}
            labelsList={this.state.labelsList}
            systemAttributes={this.state.systemAttributes}
            assignedPageField={
              this.pageFieldsOperations.findAssignedFieldForObjectById(
                  this.state.selectedObject?.objectMetadata?.id || -1
              )
            }
        />
    );
  }

  toResultReference = () => {
    const matchParams = this.props.parentProps.match.params;

    const isFinalResult = this.props.location.pathname.indexOf('/final_results/view_result/') !== -1;
    const resultId = isFinalResult ? matchParams.finalResultId : matchParams.documentId;

    return {
      result_id: resultId,
      is_final: isFinalResult
    };
  }

  render() {
    let groupBy = (arr, key) => {
      return arr.reduce((dict, item) => {
        (dict[item[key]] = dict[item[key]] || []).push(item);
        return dict;
      }, {});
    };
    let groupedTags = null
    if (this.state.allObjects) {
      groupedTags = groupBy(this.state.allObjects.map((obj, ind) => {return {...obj, id: obj.id};}), "label")
    }

    let checkedKeys = ['root-node']
    if (this.state.selectionParams !== null) {
      checkedKeys = this.state.selectionParams
    }

    let treeData = null;
    if (groupedTags !== null && this.state.tagsPanelHeight > 0) {
      // debugger;
      treeData = [
        {
          title: 'ALL',
          key: 'root-node',
          children: Object.keys(groupedTags).map((tag_type) => ({
            title: tag_type,
            key: tag_type,
            children: groupedTags[tag_type].map((tag) => ({
              title: (
                  <div onClick={() => {
                    this.zoomHandler.zoomToObject(tag.canvasObject);
                    this.setState({selectedKeys: [`${tag.id}`]});
                  }}>
                    <a>{tag.text} ({tag.metadata.description})</a>
                    {
                      this.getTagLinks(tag) && this.generateTagExternalLink(tag)
                    }
                  </div>
              ),
              checkable: false,
              key: tag.id.toString()
            }))
          })),
        }
      ];
    }
    const extendLeftPane = ["table-view", "hierarchies-view", "comparison-view", "merge-view"].includes(this.state.viewMode);
    const panelGridSize = extendLeftPane ? 8 : 6;
    const canvasGridSize = 24 - panelGridSize - this.state.rightDockSpan;

    const displayShowHideAllButton = true;

    const isInSubMode = this.state.isCreatingObject || this.state.isEditingPiping || this.state.isCreatingNewPiping
        || this.state.isCreatingMarker;

    const detectDocumentChanges = () => {
      return this.changesDetector.detect(
          {
            annotation: {
              ...this.state.baseAnnotation,
              workpacks: [],
              objects: !this.canvas ? [] : this.getCanvasObjectsDicts(),
            }
          }
      );
    }

    const actionsMenu = (
        <Menu>
          <Menu.Item id="compare-drawings-action"
                     onClick={e => this.setState({cnModalVisible: true})}
                     disabled={isInSubMode || ["comparison-view", "merge-view"].includes(this.state.viewMode)}
          >
            Compare drawings
          </Menu.Item>
          {this.props.editable &&
            <Menu.Item id="merge-drawings-action"
                       onClick={e => this.setState({mgModalVisible: true})}
                       disabled={isInSubMode || ["comparison-view", "merge-view"].includes(this.state.viewMode)}
            >
              Merge drawings
            </Menu.Item>}
        </Menu>
    );

    return (
        <>
          {this.props.editable && <EditableViewerPrompts when={detectDocumentChanges}/>}
          {/*<Row style={{flexGrow: 1, height: '100%'}}>*/}
          <Spin spinning={this.state.isLoading} size="large" style={{opacity: 1, position: 'absolute', zIndex: 100, left: '50vw', top: '30vh'}}/>
          <div style={{display: this.state.isLoading && this.state.downloadProgressText !== '' ? 'initial' : 'none', position: 'absolute', opacity: 1, left: '30vw', top: '40vh', width: '50vw'}}>
            <Progress percent={this.state.downloadProgress} status="active" strokeColor={{
              '0%': 'rgb(0,32,92)',
              '100%': 'rgb(0,75,135)',
            }}
                      style={{
                        width: 'calc(100% - 100px)',
                      }}
                      format={(percent) => this.state.downloadProgressText}
            />
          </div>
          <Row style={{...(this.state.isLoading ? {pointerEvents: 'none', opacity: 0.2 } : {pointerEvents: 'auto'}), flexGrow: 1, height: '100%'}}>

            <Row style={{flexGrow: 1, height: '100%'}}
                 onMouseUp={this.handlers?.miniMapHandler?.handleSliderMouseUp}
                 onMouseMove={this.handlers?.miniMapHandler?.handleSliderMouseMove}
            >
              <Col span={panelGridSize} style={{  }}>
                <div id="left-dock" style={{display: "flex", flexDirection: "column", height: '100%'}}>
                  <Space direction="vertical" style={{ width: "100%", paddingBottom: '8px'}}>
                    <Row>
                      {!["comparison-view", "merge-view"].includes(this.state.viewMode) &&
                        <Radio.Group onChange={this.onViewModeChange} value={this.state.viewMode} buttonStyle="solid" size="small">
                          <Radio.Button value="tree-view">Tree view</Radio.Button>
                          <Radio.Button value="table-view" disabled={isInSubMode}>Table view</Radio.Button>
                          {this.props.editable && this.props.hierarchiesAllowed &&
                              <Radio.Button value="hierarchies-view" disabled={isInSubMode}>Hierarchies</Radio.Button>}
                        </Radio.Group>
                      }
                      {this.state.selectionParams && this.state.allLabels
                          && this.isShowAllDisplayed() &&
                          displayShowHideAllButton &&
                        <Button
                          size="small" icon={<EyeTwoTone twoToneColor='rgb(0,127,197,1)'/>}
                          style={{marginLeft: 'auto', marginRight: '16px'}}
                          onClick={this.handleShowAll}
                      >Show all</Button>
                      }
                      {this.state.selectionParams && this.state.allLabels
                        && !this.isShowAllDisplayed() &&
                        displayShowHideAllButton &&
                        <Button
                            size="small" icon={<EyeOutlined/>}
                            style={{marginLeft: 'auto', marginRight: '16px'}}
                            onClick={this.handleHideAll}
                        >Hide all</Button>
                      }
                    </Row>
                    {this.state.viewMode === "table-view" && this.getTableViewFilterEditor()}
                  </Space>
                  <div id="tags-list-panel" style={{height: '40%', overflowY: 'auto'}}> {/*, display: 'flex'*//*treeData ? 0: 5*/}

                    <div style={{overflowY: 'auto', display: this.state.viewMode === "table-view" ? 'initial' : 'none'}}>
                      {this.getTableView()}
                    </div>

                    {this.canvas && this.baseAnnotation && <DrawingsComparison
                        renderObjectData={this.renderObjectData}
                        modalVisible={this.state.cnModalVisible}
                        onModalVisibilityChanged={visibility => this.setState({cnModalVisible: visibility})}
                        onCnResultsChanged={this.setCnResults}
                        onFiltersChanged={this.setCnFilters}
                        onSummaryVisibilityChanged={visibility => {
                          this.setState(
                              {viewMode: visibility ? "comparison-view" : "tree-view"},
                              this.updateObjectsVisibility
                          );
                        }}
                        canvas={this.canvas}
                        selectedCanvasObject={this.state.selectedObject}
                        anotherSelectedCanvasObject={this.state.anotherDrawingObject}
                        currentCnDrawing={new CurrentCnDrawing(this)}
                        selectCanvasObjectById={id => {
                          this.anotherDrawingObjectSelected$.next({target: null, source: 'canvas'});

                          this.zoomHandler.zoomToObject(this.canvas.getObjects().filter(obj => obj.objectMetadata)
                              .find(obj => obj.objectMetadata.id === id));
                        }}
                        selectOtherObjectById={id => {
                          const objectToSelect = this.canvas.getObjects()
                              .filter(obj => obj.fromAnotherDrawing)
                              .filter(obj => obj.objectMetadata)
                              .find(obj => obj.objectMetadata.id === id);

                          if (!objectToSelect) return;

                          this.objectSelected$.next({target: null, source: 'canvas'});
                          this.anotherDrawingObjectSelected$.next({target: objectToSelect, source: 'canvas'});

                          // this.canvas.setActiveObject(objectToSelect);

                          this.zoomHandler.zoomToObject(objectToSelect, false);
                        }}
                    />}
                    {this.canvas && this.baseAnnotation &&
                      <DrawingsMerging
                          canvas={this.canvas}
                          selectCanvasObjectById={id => {
                            this.anotherDrawingObjectSelected$.next({target: null, source: 'canvas'});

                            this.zoomHandler.zoomToObject(this.canvas.getObjects().filter(obj => obj.objectMetadata)
                                .find(obj => obj.objectMetadata.id === id));
                          }}
                          selectOtherObjectById={id => {
                            const objectToSelect = this.canvas.getObjects()
                                .filter(obj => obj.fromAnotherDrawing)
                                .filter(obj => obj.objectMetadata)
                                .find(obj => obj.objectMetadata.id === id);

                            if (!objectToSelect) return;

                            this.objectSelected$.next({target: null, source: 'canvas'});
                            this.anotherDrawingObjectSelected$.next({target: objectToSelect, source: 'canvas'});

                            this.canvas.setActiveObject(objectToSelect);

                            this.zoomHandler.zoomToObject(objectToSelect, false);
                          }}
                          selectedCanvasObject={this.state.selectedObject}
                          anotherSelectedCanvasObject={this.state.anotherDrawingObject}
                          onMgConflictsChanged={this.setMgConflicts}
                          onFiltersChanged={this.setMgFilters}
                          deleteObject={this.deleteMgObject}
                          discardSelection={() => {
                            this.anotherDrawingObjectSelected$.next({target: null, source: "canvas"});
                            this.objectSelected$.next({target: null, source: "canvas"});
                          }}
                          createNewObject={this.createNewMgObject}
                          replaceObject={this.replaceMgObject}
                          renderObjectData={this.renderObjectData}
                          currentCnDrawing={new CurrentCnDrawing(this)}
                          modalVisible={this.state.mgModalVisible}
                          onModalVisibilityChanged={visibility => this.setState({mgModalVisible: visibility})}
                          onSummaryVisibilityChanged={visibility => {
                            this.setState(
                                {viewMode: visibility ? "merge-view" : "tree-view"},
                                this.updateObjectsVisibility
                            );
                          }}
                      />
                    }

                    <AutoSizer style={{
                      height: '100%', width: '100%',
                      display: this.state.viewMode === "tree-view" ? 'initial' : 'none',
                    }}>
                      {({height}) => {
                        return treeData && (
                            <Tree checkable
                                  checkedKeys={checkedKeys}
                                  expandedKeys={this.state.expandedKeys}
                                  selectedKeys={this.state.selectedKeys}
                                  onCheck={this.handleCheck}
                                  onExpand={this.handleExpand}
                                  switcherIcon={<DownOutlined/>}
                                  treeData={treeData}
                                  height={height}
                                  ref={this.treeRef}
                            />
                        );
                      }}
                    </AutoSizer>

                    <div style={{overflowY: 'auto', display: this.state.viewMode === "hierarchies-view" ? 'initial' : 'none'}}>
                      {this.props.hierarchiesAllowed && this.props.editable && this.getHierarchiesView()}
                    </div>
                  </div>
                  {!["hierarchies-view", "comparison-view", "merge-view"].includes(this.state.viewMode) &&
                    <Drag imageViewer={this}/>
                  }
                  {!["hierarchies-view", "comparison-view", "merge-view"].includes(this.state.viewMode) &&
                  <div style={{ flex: `1 0 0`, overflowY: "hidden", display: 'flex', flexDirection: 'row'}}>
                    <div style={{
                      width: '100%',
                      height: '100%',
                    }}>
                      <div style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                      }}>
                        <div>
                          {(this.props.editable && this.state.viewMode === "tree-view") &&
                          <div style={{marginTop: '8px', marginBottom: '8px', display: 'flex', justifyContent: 'flex-end', paddingRight: '16px'}}>
                            <Space size="middle">
                              {
                                this.state.isCreatingObject &&
                                (<React.Fragment>
                                  <Button onClick={() => this.handlers.newObjectHandler.handleAbort()}
                                          danger size="small">Cancel</Button>
                                  <div id="object-label-selector">
                                    <LabelSelector
                                        labels={this.state.labelsList}
                                        currentLabel={this.state.currentLabel}
                                        addLabel={label => this.setState({
                                          labelsList: [...new Set([...this.state.labelsList, label])].sort(),
                                          currentLabel: label
                                        })}
                                        setCurrentLabel={label => this.setState({currentLabel: label})}
                                    />
                                  </div>
                                </React.Fragment>
                                )
                              }
                              {this.state.selectedObject?.objectMetadata?.shape?.shape_type === 'graph' && this.props.editable &&
                                <React.Fragment>
                                  {this.state.isCreatingNewPiping &&
                                  <Button danger size="small"
                                    onClick={() => this.handlers.graphObjectHandler.handleCancelCreatingNew()}>
                                    Cancel
                                  </Button>
                                  }
                                  {!this.state.isEditingPiping && !this.state.isCreatingNewPiping &&
                                    <Button
                                      onClick={() => this.handlers.graphObjectHandler.handleStartEditing(this.state.selectedObject)}
                                      size="small">
                                      Edit piping
                                    </Button>
                                  }
                                  {(this.state.isEditingPiping || this.state.isCreatingNewPiping) &&
                                    <React.Fragment>
                                      <ColorPicker
                                          color={this.state.selectedObject.objectMetadata?.shape?.shape_color}
                                          presetColors={COLOR_PICKER_PRESET_COLORS}
                                          onChange={color => {
                                            this.handlers.graphObjectHandler.handleSetColor(tinycolor(color.rgb).toHex8String(false));
                                            // TODO: rewrite this
                                            this.forceUpdate();
                                          }}
                                      />
                                      <Button onClick={this.handlers.graphObjectHandler.handleFinishEditing} size="small" type="primary">Finish editing</Button>
                                    </React.Fragment>
                                  }
                                </React.Fragment>
                              }
                              {!isInSubMode &&
                                <Dropdown.Button overlay={
                                  <Menu onClick={() => this.handlers.graphObjectHandler.handleCreateNew()}>
                                    <Menu.Item key="new-graph-object">
                                      New piping object
                                    </Menu.Item>
                                  </Menu>
                                }
                                    onClick={() => this.handlers.newObjectHandler.handleStart()} icon={<DownOutlined />} trigger="click"
                                    size="small" style={{marginLeft: 'auto'}}
                                >
                                  New object
                                </Dropdown.Button>
                              }
                            </Space>
                          </div>
                        }
                        </div>
                        <div style={{flex: 1, overflowY: "auto"}}>
                          {this.renderObjectData()}
                        </div>
                      </div>
                    </div>
                  </div>}
                </div>
              </Col>

              <Col id={"workspace-col"} span={canvasGridSize} style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                <Row style={{paddingBottom: '8px', alignItems: 'center'}}>
                  <Button shape="circle" size="medium" icon={<ArrowLeftOutlined />} style={{marginRight: '32px'}}
                          onClick={this.handleBack}
                  />
                  <Space size="middle">
                    { this.props.editable && <Button
                        type="primary" onClick={this.handleSave}
                        disabled={ isInSubMode }
                    >Save changes</Button>}
                    {this.props.topPanelChildren}
                    <Dropdown overlay={actionsMenu} trigger={['click']}>
                      <Button id="actions-button">
                        Actions <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Space>
                  <div style={{marginLeft: '16px'}}>File name:&nbsp;</div>
                  <div style={{maxWidth: "30%", textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', direction: 'rtl'}}>
                    <Tooltip title={this.state.fileName}>
                      <span>{this.state.fileName}</span>
                    </Tooltip>
                  </div>
                  <div style={{marginLeft: '16px'}}>
                    <FieldsEditor
                        zoomToObjectById={id => {
                          this.zoomHandler.zoomToObject(this.canvas.getObjects().filter(obj => obj.objectMetadata)
                              .find(obj => obj.objectMetadata.id === id));
                        }}
                        selectedObjectAssignData={this.pageFieldsOperations.getSelectedObjectAssignDataOrNull()}
                        fields={this.pageFieldsOperations.getPageFields()}
                        readonlyKeys={readonlyPageFieldKeys}
                        editable={this.props.editable}
                        onFieldsChange={this.pageFieldsOperations.updateFields}
                    />
                  </div>
                  {
                    this.state.pageInfo &&
                    // <div style={{marginLeft: '16px'}}>Page: {this.state.pageInfo.number + 1}/{this.state.pageInfo.total}</div>
                    <React.Fragment>
                      <div style={{marginLeft: '16px'}}>
                        Page:
                      </div>
                      <Pagination simple pageSize={1}
                                  current={this.state.pageInfo.number + 1} total={this.state.pageInfo.total}
                                  onChange={this.handlePageChange}
                      />
                    </React.Fragment>
                  }
                  <div style={{marginLeft: 'auto'}}>
                    <a>
                      <Tooltip title="Export PDF" placement="bottom">
                        <img id="export-to-pdf" src={process.env.PUBLIC_URL + '/images/export-to-pdf.svg'}
                             onClick={this.handleExportPDF}
                             width={64} height={64}
                             style={{alignSelf: 'center', marginTop: '-16px', marginBottom: '-16px', color: 'rgb(16, 126, 125)'}}
                        />
                      </Tooltip>
                    </a>
                    <span style={{paddingRight: '8px', paddingLeft: '16px'}}>Quality</span>
                    <Switch
                        checked={this.state.imageQuality === 'high'}
                        checkedChildren="high"
                        unCheckedChildren="medium"
                        style={{width: '80px', marginRight: '16px'}}
                        onChange={(checked) => this.handleQualityChange(checked)}
                    />
                    {this.state.rightDockSpan === 0 && this.props.showThumbnailsDock &&
                        <Button
                            size={"small"}
                            icon={<AppstoreOutlined/>}
                            onClick={e => this.setState({rightDockSpan: 2})}
                        />
                    }
                  </div>
                </Row>
                <div
                    style={{flexGrow: 1, width: '100%', height: '100%', overflow: 'hidden', position: 'relative'}}
                    onWheel={e => {
                      // e.persist();
                      this.onMouseWheel$.next(e);
                    }}
                >
                  <Minimap onMouseDown={this.handlers?.miniMapHandler?.handleSliderMouseDown}/>

                  <div id="cont" style={{position: 'absolute', width: '100%', height: '100%', left: 0, top: 0}}>
                    <div
                        tabIndex={1234}
                        style={{outline: 'none', width: '100%'}}
                        onKeyUp={(e) => {
                          if (this.props.editable && e.nativeEvent.code === 'KeyW' && this.mode$.value === viewerModes.NORMAL) {
                            this.handlers.newObjectHandler.handleStart();
                          }

                          if (e.nativeEvent.code === 'Delete' && this.props.editable && this.mode$.value === viewerModes.NORMAL) {
                            if (["comparison-view", "merge-view"].includes(this.state.viewMode)) {
                              message.error('The object cannot be deleted: close merge mode first!');
                            } else {
                              const activeObject = this.canvas.getActiveObject();
                              if (activeObject) {
                                let canRemove = true;

                                const activeObjectId = activeObject.objectMetadata?.id;
                                const assignedField = this.pageFieldsOperations.findAssignedFieldForObjectById(activeObjectId);

                                if (assignedField) {
                                  message.error('The object cannot be deleted: it refers to a field')
                                } else if (canRemove) {
                                  this.setState({selectedObject: null, selectedKeys: []}, () => {
                                    if (activeObject.objectMetadata?.shape?.shape_type === 'graph') {
                                      activeObject.wrappingData.helper.removeItself();
                                    } else {
                                      this.canvas.remove(activeObject);
                                    }
                                    this.objectSelected$.next({target: null, source: 'canvas'});
                                    this.objectsChanged$.next(1);
                                  })
                                }
                              }
                            }
                          }

                          if (e.nativeEvent.code === 'Delete') this.deleteKeyPressed$.next(1);

                          if (e.nativeEvent.key === 'Escape') this.escapeKeyPressed$.next(1);

                          if (e.nativeEvent.key === 'Escape' && this.mode$.value === viewerModes.CREATING_NEW_OBJECT) {
                            this.handlers.newObjectHandler.handleAbort();
                          }
                        }}
                    >
                      <div style={{display: this.state.isLoading ? 'none' : 'initial', width: '100%'}}>
                        <canvas id="c" width="50" height="50" tabIndex="2000" />
                      </div>
                    </div>
                    <div id="canvas-parent" tabIndex="1000"
                       onWheel={e => {
                         this.onMouseWheel$.next(e);
                       }}
                    >
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={this.state.rightDockSpan} style={{height: '100%'}}>
                {this.state.pageInfo && this.state.baseAnnotation && this.props.showThumbnailsDock && this.canvas &&
                    <Thumbnails
                        commentsAllowed={this.props.commentsAllowed}
                        resetSelectedObject={this.resetSelectedObject}
                        newCommentSubject={this.newCommentRequested$}
                        imageViewer={this}
                        zoomToCommentMarker={this.zoomToCommentMarker}
                        projectId={this.props.projectId}
                        currentPage={this.state.pageInfo.number}
                        total={this.state.pageInfo.total}
                        dockSpan={this.state.rightDockSpan}
                        onChangeSpan={this.setRightDockSpan}
                        onClick={thumbnailRef => {
                          const matchParams = this.props.parentProps.match.params;

                          this.changePage(
                              thumbnailRef.id,
                              thumbnailRef.is_final,
                              matchParams.projectId,
                              thumbnailRef.run_id
                          );
                        }}
                        thumbnailsSource={() => {
                          const isFinalResult = this.props.location.pathname.indexOf('/final_results/view_result/') !== -1;
                          const resultId = isFinalResult ? this.props.parentProps.match.params.finalResultId : this.props.parentProps.match.params.documentId;
                          const matchParams = this.props.parentProps.match.params;
                          const total = this.state.pageInfo.total;

                          return new BatchedCachedSource(
                              new BatchedThumbnails(
                                  matchParams.projectId,
                                  resultId,
                                  isFinalResult,
                                  total
                              ),
                              3
                          );
                        }}
                    />
                }
              </Col>
            </Row>
          </Row>
        </>
    )
  }
}

ImageViewer.contextType = SearchContext;

export default ImageViewer;
