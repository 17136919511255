import React, {useEffect, useMemo, useState} from "react";
import {Card, Col, message, Row, Space, Table} from "antd";
import {ConfigureSystemAttributesBase} from "./ConfigureSystemAttributes";
import HierarchiesService from "../../services/HierarchiesService";
import {authHeader, download} from "../../Utilities";
import {API_URL} from "../../constants";
import axios from "axios";

export function ConfigureHierarchySystemAttributes({workspaceId}) {
    const [selectedHierarchy, setSelectedHierarchy] = useState(null);
    return (
        <Row gutter={16}>
            <Col span={8}>
                <SelectHierarchy
                    workspaceId={workspaceId}
                    onSelect={setSelectedHierarchy}
                />
            </Col>
            <Col span={16}>
                {selectedHierarchy && (
                    <ConfigureHierarchySystemAttributesInner
                        key={selectedHierarchy.id}
                        workspaceId={workspaceId}
                        hierarchy={selectedHierarchy}
                    />
                )}
            </Col>
        </Row>
    );
}


function ConfigureHierarchySystemAttributesInner({workspaceId, hierarchy}) {
    const loadSystemAttributes = useMemo(() => {
        return () => HierarchiesService
            .fetchSystemAttributes(hierarchy.id)
            .then(data => ({
                data: data.map(x => ({
                    object_label: x.label,
                    attribute_name: x.name,
                    values_list: x.options
                }))
            }));
    }, [hierarchy]);

    const onDownload = useMemo(() => {
        return () => axios.get(`${API_URL}/hierarchies/${hierarchy.id}/system_attributes/config`,
            {
                headers: authHeader(),
                responseType: 'blob'
            }).then((response) => {
            const blob = new window.Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            download(blob, "hierarchy_system_attributes.xlsx");
        })
    }, [workspaceId]);

    return (
        <ConfigureSystemAttributesBase
            workspaceId={workspaceId}
            loadSystemAttributes={loadSystemAttributes}
            uploadAction={`${API_URL}/hierarchies/${hierarchy.id}/system_attributes/config`}
            onDownload={onDownload}
            mainTitle={`Configure system attributes of ${hierarchy.name}`}
        />
    );
}


function SelectHierarchy({workspaceId, onSelect}) {
    const [hierarchies, setHierarchies] = useState([]);

    useEffect(() => {
        HierarchiesService
            .fetchHierarchies(workspaceId)
            .then(({data}) => setHierarchies(data))
            .catch(_ => {message.error("Failed to load hierarchies list")})
        ;
    }, [workspaceId]);

    const hierarchiesTableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            render: (_, record) => {
                return (
                    <React.Fragment>
                        <Space>
                            <a onClick={() => onSelect(record)}>Select</a>
                        </Space>
                    </React.Fragment>
                )
            }
        }
    ];

    return (
        <Card title={"Select hierarchy to configure"}>
            <Table
                id="hierarchies-list-table"
                dataSource={hierarchies}
                columns={hierarchiesTableColumns}
            />
        </Card>
    );
}
