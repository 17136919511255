export default class Hierarchy {
  /**
   *
   * @param {string} id
   * @param {string} name
   * @param {HierarchyNode[]} nodes
   * @param {ImageViewer} imageViewer
   * @param {ViewHierarchy} hierarchyView
   */
  constructor({id, name, nodes, imageViewer, hierarchyView}) {
    this.id = id;
    this.name = name;
    this.nodes = nodes;
    this.imageViewer = imageViewer;
    this.hierarchyView = hierarchyView;
  }

  /**
   *
   * @returns {HierarchyNode | null}
   */
  getNodeById(nodeId) {
    return this.nodes.find(node => node.id === nodeId) ?? null;
  }

  /**
   *
   * @returns {HierarchyNode | undefined}
   */
  getRootNode() {
    return this.nodes.find(node => node.parent_node_id === null);
  }

  /**
   * returns null if the node doesn't exist
   * @param {string} text
   * @param {string} label
   * @returns {HierarchyNode | null}
   */
  findNode(text, label) {
    return this.nodes.find(node => node.text === text && node.label === label) ?? null;
  }

  /**
   * returns null if there is no such node
   * @param obj
   * @returns {HierarchyNode|null}
   */
  findNodeByObject(obj) {
    const node = this.findNode(obj.text, obj.label);
    if (!node) return null;
    return node;
  }

  /**
   *
   * @param value
   * @returns {boolean}
   */
  isValidSubnodesAttributeValue(value) {
    if (value.trim().length === 0) return false;
    return value.split(',').every(s => {
      const spl = s.split('|');
      if (spl.length !== 2) return false;
      return spl[0].trim().length > 0 && spl[1].trim().length > 0;
    });
  }

  /**
   *
   * @param {ImageViewerObject} obj
   * @returns {boolean}
   */
  hasSubnodesAttribute(obj) {
    return this.getSubnodesAttributesKeys(obj).length > 0;
  }

  /**
   *
   * @param {ImageViewerObject} obj
   * @returns {string[]}
   */
  getSubnodesAttributesKeys(obj) {
    return obj.metadata.attributes.filter(attr => this.isValidSubnodesAttributeValue(attr.value)).map(attr => attr.key);
  }
}
