import {Row, Space, Table, Tooltip} from "antd";
import React, {ComponentProps, useEffect, useMemo, useState} from "react";
import {WarningOutlined} from "@ant-design/icons";
import {INodeToAdd, NodeToAddSelectSource} from "../../ImageViewerHandlers/Hierarchy/NodeToAdd";
import {ColumnsType} from "antd/es/table";
import {autoUnsub, ColoredColumns} from "../../../Utilities";
import ImageViewer from "../ImageViewer";
import {ViewHierarchy} from "./ViewHierarchy";
import ImageViewerObject from "../../ImageViewerHandlers/ImageViewerObject";
import HierarchyNode from "../../ImageViewerHandlers/Hierarchy/HierarchyNode";


export const addMultipleNodesSelectSource = 'add-multiple-nodes';

export function AddMultipleNodesTable({imageViewer, viewHierarchy, dataSource, onUpdateDataSource}: {
    imageViewer: ImageViewer,
    viewHierarchy: ViewHierarchy,
    dataSource: ReadonlyArray<INodeToAdd>,
    onUpdateDataSource: (dataSource: INodeToAdd[]) => void
}) {
    const [selectedImageViewerObject, setSelectedImageViewerObject] = useState<ImageViewerObject | undefined>(undefined);
    const [selectedNode, setSelectedNode] = useState<HierarchyNode | null>(null);

    const sortedDuplicates = useMemo(() => {
        return dataSource
            .filter(el => el.duplicated(dataSource))
            .sort((a, b) => a.getText().localeCompare(b.getText()) || a.getLabel().localeCompare(b.getLabel()));
    }, [dataSource]);

    const withoutDuplicates = useMemo(() => {
        return dataSource.filter(el => !sortedDuplicates.includes(el));
    }, [dataSource, sortedDuplicates]);

    useEffect(() => {
        const scroll = (source: string) => {
            const doScroll = () => {
                if (source == addMultipleNodesSelectSource) return;
                const rowElement = document.querySelector(".add-multiple-nodes-scroll-row");
                rowElement?.scrollIntoView({ behavior: 'auto', block: 'center'});
            };

            setTimeout(() => {
                doScroll();
            }, 0);
        };

        return autoUnsub(
            imageViewer.objectSelected$.subscribe(e => {
                setSelectedImageViewerObject(imageViewer.canvasObjectToObject(e.target));
                scroll(e.source);
            }),
            viewHierarchy.nodeSelected$.subscribe(e => {
                setSelectedNode(e.target);
                scroll(e.source);
            })
        );
    }, [imageViewer]);

    const columns: ColumnsType<INodeToAdd> = [
        {
            title: "Name",
            render: (_, record, idx) => {
                const duplicated = sortedDuplicates.includes(record);
                return (
                    <div>
                        <Row justify={"space-between"}>
                            {record.getText()}
                            {duplicated && <DuplicatedWarning id={`duplicate-warning_${idx}`}/>}
                        </Row>
                    </div>
                );
            }
        },
        {
            title: "Actions",
            render: (_, record, idx) => {
                return (
                    <Space>
                        <a id={`remove-node-to-add_${idx}`}
                           onClick={e => {
                               onUpdateDataSource(dataSource.filter(item => item !== record));
                               e.stopPropagation();
                           }}
                        >
                            Remove
                        </a>
                    </Space>
                );
            }
        }
    ];

    const isSelected = (node: INodeToAdd) => {
        let result = false;
        if (selectedImageViewerObject != null) {
            if (node.isRelatedToObject(selectedImageViewerObject)) {
                result = true;
            }
        }
        if (selectedNode != null) {
            if (node.isRelatedToNode(selectedNode)) {
                result = true;
            }
        }
        return result;
    }

    const coloredColumns = new ColoredColumns(columns,
        (text: string, record: INodeToAdd, idx: number) => {
            return isSelected(record) ? "#eee" : "";
        }
    );

    return (
        <Table
            sticky={true}
            onRow={record => ({
                onClick: () => {
                    const selectSource = new NodeToAddSelectSourceAdapter(
                        imageViewer, viewHierarchy
                    );
                    record.selectOn(selectSource);
                }
            })}
            rowClassName={node => isSelected(node) ? "add-multiple-nodes-scroll-row" : ""}
            id="add-multiple-nodes-table"
            dataSource={sortedDuplicates.concat(withoutDuplicates)}
            columns={coloredColumns.values()}
            size="small"
            style={{marginTop: "8px"}}
            pagination={false}
        />
    );
}

class NodeToAddSelectSourceAdapter implements NodeToAddSelectSource {
    private readonly viewer: ImageViewer;
    private readonly viewHierarchy: ViewHierarchy;

    constructor(viewer: ImageViewer, viewHierarchy: ViewHierarchy) {
        this.viewer = viewer;
        this.viewHierarchy = viewHierarchy;
    }

    selectNode(node: HierarchyNode): void {
        this.viewHierarchy.selectNode(
            node,
            false,
            false,
            addMultipleNodesSelectSource
        );
    }

    selectObject(obj: ImageViewerObject): void {
        this.viewer.zoomHandler?.zoomToObject(
            this.viewer.objectToCanvasObject(obj),
            true,
            false,
            addMultipleNodesSelectSource
        );
    }
}

function DuplicatedWarning(props: Omit<ComponentProps<typeof Warning>, "message">) {
    return (
        <Warning {...props} message={"Node is duplicated"}/>
    );
}

function Warning({id, color = "red", message = ""}: {
    id: string,
    color?: string,
    message?: string,
}) {
    return (
        <span id={id} style={{color: color}}>
            <Tooltip title={message}>
                <WarningOutlined/>
            </Tooltip>
        </span>
    );
}