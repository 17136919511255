import {Col, Divider, Form, Input, message, Radio, Row, Select, Space} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import HierarchiesService from "../../../services/HierarchiesService";
import Highlighter from "react-highlight-words";
import {CommonFilterEditor} from "../../Misc/CommonFilterEditor/CommonFilterEditor";
import {DataSourceFiltersEditor, emptyAlias} from "../../Misc/DataSourceFilter";
import {filterOperationsMap, SearchFilter} from "../../UserView/SearchFilters/SearchFilters";
import {Option} from "antd/es/mentions";
import {EndlessScrollRemoteAutocomplete} from "../../Misc/EndlessScrollRemoteAutocomplete";
import axios from "axios";
import {authHeader} from "../../../Utilities";
import {EditedFilter} from "../../Misc/CommonFilterEditor/Model/EditedFilter";
import {ManualPagedTable} from "../../Misc/Table/ManualPagedTable";

export default function SearchInHierarchy({hierarchy, parent, closeModal}) {
    const [filterSchemas, setFilterSchemas] = useState([]);
    const [filters, setFilters] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [inputText, setInputText] = useState('');
    const dataSource = useMemo(() => ({
        values: (page, limit) => HierarchiesService.searchInHierarchy(
            hierarchy, filters.find(x => x instanceof SearchFilter)?.value ?? '', page, limit, filters
        ).then(
            response => response.data
        ).catch(() => {
            message.error('Failed to search in hierarchy');
            return [];
        })
    }), [filters, hierarchy.id]);

    useEffect(() => {
        const simpleFilterSchemaOf = attr => ({
            type: "attribute",
            attribute: attr,
            allowedValues: [],
            constructor: (value, op) => {
                return {
                    id: attr,
                    addTo: function (filters) {
                        const findIndex = filters.findIndex(el => el.id === this.id);
                        if (findIndex > -1) {
                            filters[findIndex] = this;
                        } else {
                            filters.push(this);
                        }
                        return filters;
                    },
                    getDisplayValue: () => {
                        if (op === filterOperationsMap.contains) {
                            return `*${value}*`
                        } else {
                            return value;
                        }
                    },
                    getName: () => attr,
                    getKey: () => attr + value,
                    toRequestBody: () => ({
                        key: attr,
                        target: 'node_attr',
                        value: value ?? '',
                        operation: op
                    })
                }
            }
        });

        HierarchiesService.fetchSystemAttributes(hierarchy.id).then(
            systemAttributes => setFilterSchemas([...new Set([
                ...systemAttributes.map(x => x.name),
                "Class",
                "Text"
            ])].map(attrName => simpleFilterSchemaOf(attrName)))
        ).catch(() => {message.error('Failed to load filters')})
    }, [hierarchy.id]);

    const columns = [
        {
            title: 'Text',
            dataIndex: 'text',
            render: (value) => {
                return (
                    <Highlighter
                        highlightStyle={{backgroundColor: 'var(--color-yellow)', padding: 0}}
                        searchWords={[searchQuery]}
                        autoEscape
                        textToHighlight={value}
                    />
                )
            }
        },
        {
            title: 'Class',
            dataIndex: 'label',
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            render: (id, record) => {
                return (
                    <a
                        onClick={() => {
                            parent.loadHierarchy([{text: record.text, label: record.label}], () => {
                                parent.selectNode(parent.state.hierarchy.getNodeById(id), false);
                                closeModal()
                            });
                        }}
                    >
                        Open
                    </a>
                )
            }
        }
    ]

    const handleSearch = () => {
        setSearchQuery(inputText);
        const filter = new SearchFilter('', inputText, 'Search');
        filter.addTo(filters);
        handleFiltersChanged(filters);
    }

    const handleFiltersChanged = newFilters => {
        if (!newFilters.find(x => x instanceof SearchFilter)) {
            setInputText('');
            setSearchQuery('');
        }
        setFilters([...newFilters]);
    }

    return (
        <div>
            <Input.Search
                id="hierarchy-search-box"
                onChange={e => setInputText(e.target.value)}
                value={inputText}
                enterButton
                onSearch={handleSearch}
                style={{marginBottom: '16px'}}
                size={"small"}
            />
            <FilterEditor
                hierarchyId={hierarchy.id}
                filterSchemas={filterSchemas}
                onFiltersChanged={handleFiltersChanged}
                filters={filters}
            />
            <Divider/>
            <ManualPagedTable
                id="hierarchy-search-results-table"
                asyncDataSource={dataSource}
                columns={columns}
                size={"small"}
            />
        </div>
    )
}

function FilterEditor({hierarchyId, filters, onFiltersChanged, filterSchemas}) {
    const fetchOptionsUrl = HierarchiesService.getPossibleSearchOptionsUrl(hierarchyId);
    const filtersRenderer = (tagsList, editor) => (
        <Row justify={"space-between"} style={{marginBottom: 8}}>
            <Col span={15}>
                {tagsList}
            </Col>
            <Col>
                {editor}
            </Col>
        </Row>
    );

    return (
        <DataSourceFiltersEditor
            itemFilters={filters}
            onItemFiltersChanged={onFiltersChanged}
            filterSchemas={filterSchemas}
            contentRenderer={filtersRenderer}
            fieldEditorRenderer={(filterSchemas, addNewFilter) => (
                <CommonFilterEditor
                    editors={[
                        {
                            type: 'attribute',
                            tabName: 'Attribute',
                            render: (schemasByType, onEditedFilterChanged) => (
                                <NodeAttributeFilterEditor
                                    onEditedFilterChanged={onEditedFilterChanged}
                                    filterSchemas={schemasByType}
                                    attributeLabel={"Attribute"}
                                    fetchOptionsSchema={{url: fetchOptionsUrl, option_target: 'node_attr'}}
                                />
                            )
                        }
                    ]}
                    filterSchemas={filterSchemas}
                    onNewFilter={addNewFilter}
                />
            )}
        />
    );
}


class NodeAttributeEditedFilter extends EditedFilter {
    constructor(attr, value, operation, schemas) {
        super();
        this._attr = attr;
        this._value = value;
        this._operation = operation;
        this._schemas = schemas;
    }

    canBeAdded() {
        return this._attr && this._operation;
    }

    toConcreteFilter() {
        const schema = this._schemas.find((schema) => schema.attribute === this._attr);
        const newFilter = schema.constructor(this._value, this._operation);
        return newFilter;
    }
}


function NodeAttributeFilterEditor({fetchOptionsSchema, filterSchemas, attributeLabel, onEditedFilterChanged}) {
    const [selectedAttribute, setSelectedAttribute] = useState(null);
    const [selectedValue, setSelectedValue] = useState("");
    const [filterOperation, setFilterOperation] = useState(filterOperationsMap.equal);

    const allowedAttributes = [...new Set(filterSchemas.map((schema) => schema.attribute))];

    const searchableSelectProps = {
        size: "small",
        showSearch: true,
        filterOption: filterOption,
    }

    function filterOption(input, option) {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }

    const updateAttribute = attr => {
        onEditedFilterChanged(
            new NodeAttributeEditedFilter(attr, null, filterOperation, filterSchemas)
        );
        setSelectedAttribute(attr);
        setSelectedValue(null);
    }

    const updateValue = value => {
        setSelectedValue(value || '');
        onEditedFilterChanged(
            new NodeAttributeEditedFilter(selectedAttribute, value, filterOperation, filterSchemas)
        );
    }

    const updateOperation = operation => {
        setFilterOperation(operation);
        onEditedFilterChanged(
            new NodeAttributeEditedFilter(selectedAttribute, selectedValue, operation, filterSchemas)
        );
    }

    const valueInput = (
        <Input id="value-input"
               size="small"
               value={selectedValue}
               onChange={e => updateValue(e.target.value)}
        />
    );

    const valueAutocomplete = (
        <EndlessScrollRemoteAutocomplete
            key={selectedAttribute}
            mapValueLabel={v => v || emptyAlias}
            fetchOptions={(value, maxResults) => {
                const params = {
                    option_key: selectedAttribute,
                    option_value_part: value,
                    max_results: maxResults,
                    option_target: fetchOptionsSchema.option_target
                }

                return axios.get(
                    fetchOptionsSchema.url,
                    {
                        params,
                        timeout: 30 * 1000,
                        headers: authHeader()
                    }
                )
            }}
            onSelect={updateValue}
            onChange={updateValue}
        />
    );

    return (
        <Form labelCol={{span: 6}} wrapperCol={{span: 18}}>
            <Form.Item label={attributeLabel}>
                <Select
                    id="attribute-select"
                    {...searchableSelectProps}
                    value={selectedAttribute}
                    onSelect={updateAttribute}
                >
                    {
                        allowedAttributes.map((attr, idx) => {
                            const option = attr === "" ? emptyAlias : attr;
                            return (<Option id={`attribute-select-option_${attr}`} value={attr}>{option}</Option>);
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item label="Value">
                {filterOperation === filterOperationsMap.contains ? valueInput : valueAutocomplete}
            </Form.Item>
            <Radio.Group
                onChange={e => updateOperation(e.target.value)}
                defaultValue={filterOperationsMap.equal}
            >
                <Space direction="vertical">
                    <Radio value={filterOperationsMap.equal}>Full match</Radio>
                    <Radio value={filterOperationsMap.contains}>Partial match</Radio>
                </Space>
            </Radio.Group>
        </Form>
    );
}
