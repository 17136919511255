import {useEffect} from "react";
import ImageViewer from "../ImageViewer";
import {ViewHierarchy} from "./ViewHierarchy";
import {ExistentNodeToAdd, ObjectToAdd} from "../../ImageViewerHandlers/Hierarchy/NodeToAdd";
import {autoUnsub} from "../../../Utilities";

export function NodeBufferListener({imageViewer, viewHierarchy}: {
    imageViewer: ImageViewer,
    viewHierarchy: ViewHierarchy
}) {

    useEffect(() => {
        return autoUnsub(
            imageViewer.objectMouseClicked$.subscribe(objectClicked => {
                const obj = imageViewer.canvasObjectToObject(objectClicked.target);
                const e = objectClicked.e;
                if (e?.ctrlKey === true && obj?.isValidForHierarchy()) {
                    viewHierarchy.addOrRemoveFromMultipleNodesList(new ObjectToAdd(obj));
                }
            }),
            viewHierarchy.nodeClicked$.subscribe(({e, node}) => {
                if (e.ctrlKey) {
                    const obj = node.findReferencedObject();
                    if (obj?.isValidForHierarchy()) {
                        viewHierarchy.addOrRemoveFromMultipleNodesList(new ObjectToAdd(obj));
                    } else {
                        viewHierarchy.addOrRemoveFromMultipleNodesList(new ExistentNodeToAdd(node));
                    }
                }
            })
        );
    }, [imageViewer, viewHierarchy]);

    return null;
}